.collab-card-holder{
    height: auto;
    width: 240px;
    padding: 12px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: black solid 2px;
    border-radius: 10px;
    margin-top: 0;
}
.collab-card-holder:hover{
    border: solid 2px orange;;
    color: orange;
    transition: 0.2s ease-in-out;
    
}

.collab-card-holder img{
    height: 200px;
    width: 200px;
}
.collab-card-holder div{
    border-top: solid 1px ;
}
.partnerships{
    display: flex;
    margin: 0px 20px;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}
.pty h3{
    font-weight: 700;
    text-align: center;
}