.poster-image{
    max-height: 500px;
}
.poster-box{
    display: flex;
    padding: 24px;
    width: 100%;
    justify-content: center;
    gap: 80px;
    border-bottom: solid 1px orange;
}
.poster-box:nth-child(3){
    border-bottom: none;
}
.poster-body{
    max-width: 400px;
}
.poster-body p{
    margin: 4px;
}
.poster-body h2{
    padding: 0%;
    font-size: 1.5rem;
    font-weight: 900;
     color: #f4a24c; 
    margin-top: 0%;
}
.middletext{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.middletext h4{
    margin: 8px 0;
    padding: 4px;
    font-weight: bold;
}
.middletext button{
    width: 120px;
    text-decoration: none;
    margin: 8px;
    border-radius: 4px;
    color: black;
    padding: 8px 0;
    background:  #f4a24c;
    display: inline-block;
    border: none;
}
.middletext button:hover{
    background: black;
    color: orange;
    text-decoration: wavy;
}
.middletext a:hover{
    color: orange;
}
.middletext a{
    text-decoration: none;
    color: black;
}
@media screen and (max-width: 800px) {
    .poster-box{
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width: 600px) {
    .poster-body{
        padding: 20px;
    }
}
@media screen and (max-width: 400px){
    .poster-box{
        margin: 0%;
    }
}

