/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px; /* Ensures padding on smaller screens */
}

/* Modal Container */
.modal-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    min-width: 400px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    transition: 0.3s ease-in-out;
}

/* Responsive Modal */
@media (max-width: 600px) {
    .modal-container {
        width: 90%;
        min-width: unset;
        max-height: 90vh;
    }
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    background: none;
}

/* Vendor Header */
.vendor-header {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.vendor-logo {
    max-width: 100px;
    height: auto;
    border-radius: 50%;
}

/* Responsive Vendor Name */
@media (max-width: 600px) {
    .vendor-header {
        flex-direction: column;
        text-align: center;
    }
}

/* Vendor Category */
.vendor-category {
    color: gray;
}

.vendor-inner-square {
    display: flex;
    height: 440px;
    width: 280px;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    height: 100%; /* Make sure it takes up the full height of the card */
    text-align: center;
}

.vendor-inner-square img {
    width: 200px; /* Ensure the image fits within its container */
    height: auto; /* Maintain the aspect ratio of the image */
    object-fit: fill; /* Scale the image to fit within the container without distortion */
    border: solid 1px #a3a3a3;
    aspect-ratio: 1/1;
}

/* Tab Navigation */
.tab-navigation {
    display: flex;
    gap: 10px;
    margin: 15px 0;
    flex-wrap: wrap; /* Ensures tabs wrap on small screens */
}

.tab-navigation button {
    padding: 8px 16px;
    border: none;
    background: lightgray;
    cursor: pointer;
    border-radius: 5px;
    flex-grow: 1; /* Ensures buttons scale evenly */
    text-align: center;
}

.tab-navigation button.active {
    background: #0073e6;
    color: white;
}

/* Content Sections */
.tab-content {
    padding: 10px;
}

/* Responsive Photo Grid */
.photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
}

/* Photo Items */
.photo-item {
    width: 100%;
    border-radius: 5px;
}

.vendor-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 3fr));
    gap: 12px;
}

/* Adjustments for small screens */
@media (max-width: 600px) {
    .photo-grid {
        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    }
}

/* Highlight vendor card if it has a discount */
.vendor-square {
    position: relative;
    padding: 10px;
    border: 2px solid transparent;
    transition: border 0.3s ease-in-out;
}

.vendor-square.highlight-green img {
    border: 2px solid green;
}

/* Discount badge in the top-right corner */
.discount-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    background: green;
    color: white;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
}
