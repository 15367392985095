.gallerypanes {
  border-top: solid 2px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  gap: 12px;
  padding: 20px;
}
.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}
.imageholderportrait {
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-row: span 2 / auto;
    grid-auto-rows: minmax(150px, auto);
    padding: 4px;
  }
.imageholderportrait img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5%;
}
.imageholdersquare{
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 4px;
}
.imageholdersquare img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5%;

}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.galleryheader{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin: 20px 0;
}
.galleryheader button{
  background: transparent;
  font-size: large;
  padding: 12px 24px;
  color: black;
  transform-origin: bottom;
}
.galleryheader button:hover{
  background: #fff;
  color: black;
  border: solid 2px orange;
  box-shadow: 8px 0px 8px 0 rgb(0,0,0,1);
  transition: 300ms;
  transform: scale(102%);
}

/* Spin animation for the loading spinner */
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* Image fade-in animation */
.lazy-image {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.lazy-image.fade-in {
  opacity: 1;
}

/* Positioning for images */
.lazy-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Ensure images take full width */
.lazy-image {
  width: 100%;
  height: auto;
  display: block;
}
.outer-gallery{
  display: flex;
  flex-direction: column;
  border: solid 2px;
}
.buttons{
  justify-content: space-between;
}
.buttons button{
  margin: 0 8px;
}
.list-buttons{
  margin-top: 4px;
}
.list-buttons button{
  padding: 4px;
  background-color: #fff;
  border-radius: 0px;
  font-size: small;
  font-weight: 500;
}
.list-buttons button:hover{
  background-color: rgb(255, 214, 138);
}
.filter-button-div{
  margin: 0 20px;
}