.contact{
    display: block;
}
.form-img{
    display: flex;
    flex-direction: column;
    background-image: url('../images/contact-bg/art-7537962_1920.jpg');
    padding-top: 20px;
    height: 300px;
    align-items: center;
    justify-content: center;
    
}
.form-img img{
    height: 20%;
}
.Form{
    display: flex;
    flex-direction: column;
    min-width: 50%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}
/* Optionally, you can hide elements using classes instead of inline styles */
.hidden {
    display: none; /* Or visibility: hidden; depending on your needs */
}
.Form label{
    height: auto;
    padding: 12px 0;
}
.Form input{
    width: 250px;
}

.form-switcher button{
    margin: 12px;
    width: 100px;;
}
.active{
    background-color: rgba(255, 166, 0, 0.342);
    color: black;
    box-shadow: inset;
    font-size: medium;
    transform: ease-in 2ms;
}
.form-names{
    display: grid;
}
.form-names input{
    margin-top: 4px;
    margin-bottom: 4px;
}

@media only screen and (max-width: 700px) {
    .contact{
        flex-wrap: wrap;
    }
}