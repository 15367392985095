/* The Lightbox (Modal) */
.lightbox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

/* Lightbox image */
.lightbox-content {
    max-width: 90%;
    max-height: 90%;
}

/* Close button */
.close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

/* Next & previous buttons */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    color: white;
    font-size: 40px;
    padding: 16px;
    margin-top: -22px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}
