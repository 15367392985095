.hero-section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Blurry Background */
.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(10px); /* Apply blur effect */
  transform: scale(1.1); /* Slightly enlarge to avoid blurred edges */
  z-index: -1;
}

/* Main Slideshow Content */
.image-house {
  position: relative;
  max-width: 800px;
  z-index: 1;
}

.image-house img {
  width: 100%;
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Adds depth */
}

/* Responsive Design */
@media only screen and (max-width: 600px) {
  .hero-section {
      max-height: 50vh;
      max-width: 600px;
  }
}
