.About{
    display: flex;
    justify-content: center;
    word-spacing: 1px;
    letter-spacing: 1px;
    
}
.About div{
    margin:20px;
}
.About li{
    padding: 8px 0px;
    word-spacing: 1px;
}
.outer-about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.about-image:nth-child(even){
    width: 240px;
    background: url(/public/images/7639418.jpg) center / cover  ;
    height: 240px;
}
.about-image:nth-child(odd){
    width: 240px;
    background: url(/public/picassoteam.jpg) center / cover ;
    height: 240px;
}
.About-write-up{
    width: 50%;
    border-top: solid 1px;
    flex-wrap: wrap;

}