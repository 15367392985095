.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}


.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  
}

.nav-items {
  display: flex;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 50;
}

.nav-item {
  list-style: none;
}

.nav-link {
  text-decoration: none;
  font-weight: 400;
  color: #333;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: orange;
}

/* === HAMBURGER MENU STYLING === */
.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffff;
  backdrop-filter: blur(10px);
  list-style: none;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: none;
  flex-direction: column;
}

.dropdown:hover .dropdown-menu {
  display: flex;
}

.dropdown-menu li {
  padding: 5px 10px;
}

.dropdown-menu li .nav-link {
  color: #333;
}

.dropdown-menu li .nav-link:hover {
  color:  #f4a24c;; 
}
/* === Ticker === */
.ticker-container {
  width: 100%;
  background: #f4a24c;;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  margin-bottom: 8px;
  height: 35px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.ticker-wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.ticker {
  display: flex;
  min-width: 200%;
  animation: ticker-scroll 15s linear infinite;
}

.ticker p {
  font-size: 14px;
  color: #ffff;
  margin: 0;
  padding: 0 20px;
}

/* Animation */
@keyframes ticker-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

/* Adjust Navbar Position */
.navbar {
  margin-top: 35px; /* Push navbar down to avoid overlap */
}




/* === MOBILE RESPONSIVENESS === */
@media (max-width: 500px) {
  .hamburger {
    display: block;
  }

  .nav-items {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    width: 100vw;
    margin: 0 20px;
  }

  .nav-items.open {
    display: flex;
  }

  .nav-item {
    padding: 10px 0;
    text-align: center;
  }
}
