.vendor-square {
    box-sizing: border-box; /* Include padding and border in the width calculation */
    color: black;
    margin-top: 0;
}
.vendor-square:hover{
    margin-top: -10px;
    margin-bottom: 10px;
    transition: 0.2s ease-in-out;
    color: orange;

}
.vendor-square:hover img{
    border: solid 1px orange;

}

.vendor-inner-square {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    height: 100%; /* Make sure it takes up the full height of the card */
    text-align: center;
}

.vendor-inner-square img {
    width: 200px;
    min-width: 150px;
    height: auto; /* Maintain the aspect ratio of the image */
    object-fit:fill; /* Scale the image to fit within the container without distortion */
    border-radius: 20%;
    border: solid 1px #a3a3a3;
    aspect-ratio: 1/1;
}
