.festival-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center content better */
    align-items: center;
    gap: 40px;
}

.spacer {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.layer1 {
    padding: 20px;
}

.layer1 h3 {
    text-align: center;
    margin: 0px;
}

.festival-image {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
}

.festival-writer {
    max-width: 100%;
    word-wrap: break-word;
    text-align: center;
    padding: 15px;
    min-height: 100px;
    font-size: 16px;
}

.countdown {
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.countdown-timer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.countdown-timer div {
    backdrop-filter: blur(8px);
    padding: 15px;
    width: 60px;
    min-width: 50px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    text-align: center;
    border: solid 1px;
}

.countdown-timer span {
    font-size: 1.5rem;
    font-weight: bold;
}

.countdown-timer p {
    font-size: 0.8rem;
    margin-top: 5px;
    color: black;
}

.countdown button {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    max-width: 200px;
    width: auto;
}
.event-cards{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
/* General container for the two sections */
.event-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 20px; /* Adds spacing between the columns */
    max-width: 1100px;
    margin: 0 auto;
}

/* Individual event sections */
.festivals, .offroad {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Pushes the button to the bottom */
    width: 500px;
    max-width: 100%;
    margin: 10px;
    min-height: 550px; /* Ensures both sections have the same height */
}

/* Ensure text grows evenly so content stays balanced */
.festivals div, .offroad div {
    flex-grow: 1;
}

/* Event images */
.festivals img, .offroad img {
    width: 100%; /* Makes images scale within their container */
    max-width: 500px;
    max-height: 320px;
    height: auto;
    border-radius: 10px;
}
.festivals h3, .offroad h3{
    text-align: left;
    font-weight: 900;
    font-size: 1.5rem;
}
/* Paragraph styling */
.festivals p, .offroad p {
    text-align: justify;
    word-spacing: -4px;
    min-height: 240px;
}

/* Links (View details button) */
.festivals a, .offroad a {
    text-decoration: none;
    border-radius: 4px;
    color: black;
    padding: 8px;
    background: #f4a24c;
    display: inline-block;
    margin-top: auto; /* Pushes the button to the bottom */
}

/* Hover effects for buttons */
.festivals a:hover, .offroad a:hover {
    color: #f4a24c;
    background: black;
}

/* --- MEDIA QUERIES FOR RESPONSIVENESS --- */

/* Medium screens (Tablets and smaller desktops) */
@media only screen and (max-width: 700px) {
    .event-cards {
        flex-direction: column; /* Stacks items vertically */
        align-items: center;
    }

    .festivals, .offroad {
        width: 90%; /* Ensures they fit within the viewport */
        max-width: 400px; /* Slightly smaller for compact screens */
        min-height: auto; /* Removes unnecessary height restriction */
    }

    .festivals img, .offroad img {
        max-width: 100%;
    }
}

/* Small screens (Mobile phones) */
@media only screen and (max-width: 400px) {
    .festivals, .offroad {
        max-width: 300px; /* Further reduce width on extra small screens */
    }

    .festivals img, .offroad img {
        max-width: 100%;
    }
}
