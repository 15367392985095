.footer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: solid 1px;
    font-size: 1rem;
    font-weight: 400;
    padding: 20px;
    background: #f4a24c;
    color: white;

}
.footer div{
    padding: 12px;
    width: 300px;
}
.footer a{
    text-decoration: none;
}
.socials{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and  (max-width: 700px){
    .footer{
        align-self: center;
        text-align: justify;
        letter-spacing: 1px;
    }
    .footer div{
        padding: 8px;
        width: auto;
        font-size: 1rem;
    }
    
}