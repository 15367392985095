/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
}

/* Column Styles */
.column1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px auto;
    width: 90%;
    max-width: 1200px;
}
.column1 img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.column-1 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 20px auto;
    width: 90%;
    max-width: 1200px;
}
.column-1 > .writeup-body {
    margin: 0px;
    text-align: center;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: auto;
}

/* Writeup Section */
.writeup-body {
    padding: 20px;
    text-align: justify;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.writeup-body h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #ff9f43; /* Original orange accent color */
}
.writeup-body img {
    max-width: 100%;
    border-radius: 8px;
}

/* Ticket Section */
.ticket-section {
    text-align: center;
    align-self: center;
    padding: 20px;
    background: rgba(255, 247, 230, 0.9);
    border-radius: 10px;
    margin: 20px auto;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ticketbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.ticket-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 150px;
    text-align: center;
    flex: 1;
    width: auto;
}

.ticket-card h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
}

.ticket-card p {
    font-size: 1em;
    color: #555;
    font-weight: bold;
}

.ticket-link {
    margin-top: 20px;
}

.buy-tickets-button {
    background: #ff6f61; /* Original button color */
    color: #fff;
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.buy-tickets-button:hover {
    background: #ff3b2d; /* Original hover color */
}

/* Activities Section */
.row2 {
    text-align: center;
    margin: 20px auto;
    width: 90%;
}
.row2 h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #ff6f61; /* Original section heading color */
}

.activities-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    margin: 0 auto;
    width: 90%;
    max-width: 1200px;
}
.grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
}
.grid img {
    width: 80px;
    margin-bottom: 10px;
}
.grid li {
    list-style: none;
    text-align: center;
}

/* Poster */
.poster {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    width: 90%;
}
.poster img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff6f61;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
}

/* Responsive Design */
@media (min-width: 768px) {
    .column-1 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .activities-grid {
        grid-template-columns: repeat(5, 1fr);
    }
}
